import Box from "@mui/material/Box/Box";
import { InfoSection, InfoIcon } from "./styles";
import Text from "../../../components/Text/Text";
import Stack from "@mui/material/Stack/Stack";
import Button from "../../../components/Button/Button";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import localization from "localization/sv";
import { useState } from "react";
import { TextDialog } from "./TextDialog";

type Props = {
  infoText: string;
  videoUrl: string;
  bodyText: string;
  submitButtonLabel?: string;
  modalText?: string;
  modalTitle?: string;
  onNext: () => void;
  onSubmit: () => boolean | undefined;
  onPrevious: () => void;
};

const VideoInstruction = ({
  infoText,
  videoUrl,
  bodyText,
  submitButtonLabel,
  modalText,
  modalTitle,
  onNext,
  onSubmit,
  onPrevious,
}: Props) => {
  const [open, setOpen] = useState(false);

  const customSubmit = async () => {
    if (onSubmit) {
      const hasErrors = await onSubmit();
      if (!hasErrors && modalText) {
        setOpen(true);
      }
    } else {
      onNext();
    }
  };

  const handleClose = () => {
    open && setOpen(false);
    onNext();
  };

  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        {infoText && (
          <InfoSection>
            <Stack direction="row" alignItems="center" spacing={1}>
              <InfoIcon />
              <Text customStyling={{ width: "100%" }} type="body1">
                {infoText}
              </Text>
            </Stack>
          </InfoSection>
        )}
        <video src={videoUrl} width="100%" controls loop>
          <source src={videoUrl} type="video/mp4" />
        </video>

        <Box sx={{ padding: 4 }}>
          <Text
            customStyling={{ paddingBottom: "20px", textAlign: "center" }}
            type="body1"
          >
            {bodyText}
          </Text>
          <Stack direction="row" spacing={{ xs: 2, sm: 2 }}>
            <Button
              startIcon={<HighlightOffOutlinedIcon />}
              title={localization.general.cancel}
              color="error"
              onClick={onPrevious}
            ></Button>
            <Button
              startIcon={<CheckCircleOutlineIcon />}
              title={submitButtonLabel || localization.general.submit}
              color="primary"
              onClick={customSubmit}
            ></Button>
          </Stack>
        </Box>
      </Box>
      {modalText ? (
        <TextDialog
          text={modalText}
          title={modalTitle}
          open={open}
          handleClose={handleClose}
        />
      ) : null}
    </div>
  );
};

export default VideoInstruction;
