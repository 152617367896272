import { createSlice } from "@reduxjs/toolkit";
import {
  loadDevice,
  updateDevice,
  loadDeviceMeasurements,
  saveDeviceIccid,
} from "./actions";

interface DeviceCurrentState {
  errorMessage: string | null;
}
export interface DeviceState {
  current: DeviceCurrentState;
  dosellSoftwareVersion: string;
  dosellTimezone: string;
  dosellType: string;
  dosellDispBtn: string;
  dosellDispBtnTmo: number;
  dosellDispWindow: number;
  dosellDemo: string;
  dosellChargingLevel: string;
  ectoken: string;
  timeutc: string; // ISO timestamp as a string
  dosellBtEnabled: string;
  dosellStripCtrl: string;
  dosellDispenseCtrl: string;
  dosellSoundLevel: number;
  dosellSoundType: number;
  dosellFirstDispTime: string; // ISO timestamp as a string
  dosellLastDispDate: string; // ISO timestamp as a string
  dosellDaysBeforeLastDispDateAlert: number;
  dosellDaysBeforeLastDispDateNotif: number;
  dosellMinBeforeUncolSachetNotif: number;
  dosellAP: string;
  onlineAt: string;
  iccid: string;
  dosellStripState?: 0 | 1 | 2 | 3;
  measurements: any;
}

const initialState: DeviceState = {
  current: { errorMessage: null },
  onlineAt: "",
  dosellSoftwareVersion: "",
  dosellTimezone: "",
  dosellType: "",
  dosellDispBtn: "",
  dosellDispBtnTmo: 0,
  dosellDispWindow: 0,
  dosellDemo: "",
  dosellChargingLevel: "",
  ectoken: "",
  timeutc: "",
  dosellBtEnabled: "",
  dosellStripCtrl: "",
  dosellDispenseCtrl: "",
  dosellSoundLevel: 0,
  dosellSoundType: 0,
  dosellFirstDispTime: "",
  dosellLastDispDate: "",
  dosellDaysBeforeLastDispDateAlert: 0,
  dosellDaysBeforeLastDispDateNotif: 0,
  dosellMinBeforeUncolSachetNotif: 0,
  dosellAP: "",
  iccid: "",
  measurements: null,
};

const deviceSlice = createSlice({
  name: "device",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadDevice.fulfilled, (state, action) => {
      return {
        ...state,
        ...action.payload,
        current: { ...state.current, errorMessage: null },
      };
    });
    builder.addCase(loadDevice.rejected, (state, action) => {
      state.current.errorMessage = action.payload as string;
    });
    builder.addCase(updateDevice.fulfilled, (state, action) => {
      return { ...state, ...action.payload };
    });
    builder.addCase(loadDeviceMeasurements.fulfilled, (state, action) => {
      return { ...state, measurements: action.payload };
    });
    builder.addCase(saveDeviceIccid.fulfilled, (state, action) => {
      state.iccid = action.payload.iccid;
    });
  },
});

export default deviceSlice.reducer;
