const primary = {
  main: "#92cbc5",
  dark: "#44c9bb", // hover
  light: "#F6F1FF",
};

const secondary = {
  main: "#F6F1FF",
  light: "#F6F1FF",
};

const error = {
  main: "#f44336",
  light: "#FEF3F2",
};

const info = {
  main: "#616D87",
  light: "#E0E2E9",
};

const grey = {
  100: "#F6F6F8",
  200: "#F0F1F5",
  300: "#E0E2E9",
  400: "#848DA9",
  500: "#373737",
  600: "#1A1921",
};

const text = {
  primary: "#1A1921",
  secondary: "#616D87",
  white: "#fff",
  grey: grey[400],
  darkGrey: grey[500],
  primaryMain: primary.main,
};

const background = { default: text.darkGrey };

export const palette = {
  primary,
  secondary,
  error,
  info,
  grey,
  text,
  background,
};
