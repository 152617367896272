// src/axiosConfig.ts
import axios from "axios";

// Create an Axios instance with custom configuration
const axiosInstance = axios.create({
  baseURL: "https://production.dosell.com/api/partner", // Base URL for all requests
  headers: {
    Accept: "*/*",
    Authorization:
      "Bearer pltMnl4bFvrWuHnZl8DBPsA8/CdUvEGRe6WSPP1EqR15rkjLgH2tX0lRL9nNq+tg",
  },
});

export default axiosInstance;
