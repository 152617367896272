import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { Container, StyledTimePickerField } from "./styles";
import { ComponentProps } from "components/types";
import dayjs from "dayjs";

type Props = {
  title?: string;
  value: number | null;
  onChange: (v: number | null) => void;
} & ComponentProps;

export const TimePicker = (props: Props) => {
  const { title, onChange, value } = props;

  const convertToDateJS = (date: number | null) => {
    return date === null ? null : dayjs(date);
  };

  return (
    <Container>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StyledTimePickerField
          value={convertToDateJS(value)}
          ampm={false}
          label={title}
          onChange={(value) => onChange(value?.toDate().getTime() || null)}
        />
      </LocalizationProvider>
    </Container>
  );
};
