import { styled } from "@mui/material/styles";
import Box, { boxClasses } from "@mui/material/Box";

export const InfoSection = styled(Box)(({ theme }) => ({
  [`&.${boxClasses.root}`]: {
    border: `1px solid ${theme.palette.primary.light}`,
    padding: "15px 20px",
    borderRadius: "10px",
    textAlign: "center",
    margin: "0 0 20px 0",
  },
}));

export const boxStyles = { flexGrow: 1, padding: 4 };
export const dividerStyles = { backgroundColor: "white", margin: 1 };
