import { styled } from "@mui/material/styles";
import Box, { boxClasses } from "@mui/material/Box";
import InfoOutlined from "@mui/icons-material/InfoOutlined";

export const InfoSection = styled(Box)(({ theme }) => ({
  [`&.${boxClasses.root}`]: {
    border: `1px solid ${theme.palette.primary.light}`,
    padding: "15px 20px",
    borderRadius: "10px",
    textAlign: "center",
    margin: "32px 32px 20px 32px",
  },
}));

export const InfoIcon = styled(InfoOutlined)(({ theme }) => ({
  "&": {
    color: theme.palette.primary.main,
    fontSize: 30,
  },
}));
