import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

import { textFieldClasses } from "@mui/material/TextField";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { inputAdornmentClasses } from "@mui/material/InputAdornment";
import { inputLabelClasses } from "@mui/material/InputLabel";
import { formLabelClasses } from "@mui/material/FormLabel";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { iconButtonClasses } from "@mui/material/IconButton";

export const Container = styled(Box)(() => ({
  "&": {
    width: "100%",
  },
}));

export const StyledDatePicker = styled(DesktopDatePicker)(({ theme }) => ({
  [`&.${textFieldClasses.root}`]: {
    borderRadius: "20px",
    width: "100%",
    color: theme.palette.secondary.main,

    [`& .${outlinedInputClasses.notchedOutline}`]: {
      borderColor: theme.palette.grey[200],
      top: 0,
      borderRadius: "8px",
      legend: {
        display: "none",
      },
    },

    // Hover
    [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
      borderColor: theme.palette.primary.main,
      borderWidth: "1px",
    },

    // Error
    [`&.${outlinedInputClasses.error} .${outlinedInputClasses.notchedOutline}`]:
      {
        borderColor: theme.palette.error.main,
      },

    // Focused
    [`&.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]:
      {
        borderWidth: "1px",
      },

    [`& .${inputLabelClasses.root}`]: {
      color: theme.palette.secondary.main,
      fontSize: theme.typography.body2.fontSize,
      fontWeight: theme.typography.body2.fontWeight,
      left: "30px",
      transform: "translate(14px, 12px) scale(1)",
      transition: "opacity ease .3s",
      opacity: 1,

      [`&.${inputLabelClasses.focused}, &.${formLabelClasses.filled}`]: {
        opacity: 0,
      },
    },
    "& .MuiInputBase-root": {
      fontSize: theme.typography.body2.fontSize,
      fontWeight: theme.typography.body2.fontWeight,
      display: "flex",
      flexDirection: "row-reverse",
      lineHeight: "1.334em",
      paddingRight: 0,
      color: theme.palette.secondary.main,

      [`& .${iconButtonClasses.root}`]: {
        color: theme.palette.grey[300],
      },

      "& input": {
        padding: "17px 14px",
        height: "1em",
      },

      [`& .${inputAdornmentClasses.root} button`]: {
        padding: "4px",
      },
    },
  },
}));
