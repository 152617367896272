import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface CurrentState {
  [key: string]: number | null;
}

const initialState: CurrentState = {
  firstDispDate: null,
  firstDispTime: null,
  lastDispDate: null,
};

const currentStateSlice = createSlice({
  name: "currentState",
  initialState,
  reducers: {
    // Action to store step data
    saveStepData: (
      state,
      action: PayloadAction<{ fieldId: string; date: number | null }>,
    ) => {
      const { fieldId, date } = action.payload;
      state[fieldId] = date;
    },
    clearStepData: (state) => ({ ...state, ...initialState }),
  },
});

export const { saveStepData, clearStepData } = currentStateSlice.actions;

export default currentStateSlice.reducer;
