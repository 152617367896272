import { styled } from "@mui/material/styles";
import Button, { buttonClasses } from "@mui/material/Button";

import { Align, FontSize } from "./Button";

export const StyledButton = styled(Button)<{
  fontSize: FontSize;
  align?: Align;
  width?: "auto" | "100%";
}>(({ theme, fontSize, align, width, variant }) => ({
  [`&.${buttonClasses.root}`]: {
    fontSize: theme.typography[fontSize].fontSize,
    fontWeight: "600",
    textTransform: "none",
    borderRadius: "10px",
    padding: variant === "text" ? null : "10px 24px",
    width,
    boxShadow: "none",
    justifyContent: align,
    [`&.${buttonClasses.textPrimary}`]: {
      color: theme.palette.primary.main,
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    [`&.${buttonClasses.textSecondary}`]: {
      color: theme.palette.text.secondary,
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    [`&.${buttonClasses.containedPrimary}`]: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,

      [`&.${buttonClasses.disabled}`]: {
        pointerEvents: "unset",
        cursor: "not-allowed",
        opacity: "0.5",
      },
    },
    [`&.${buttonClasses.containedSecondary}`]: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.common.black,
    },
    [`&.${buttonClasses.containedInfo}`]: {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.light,

      [`&.${buttonClasses.disabled}`]: {
        pointerEvents: "unset",
        cursor: "not-allowed",
        opacity: "0.5",
      },
    },
    [`&.${buttonClasses.containedSuccess}`]: {
      color: theme.palette.common.white,

      [`&.${buttonClasses.disabled}`]: {
        pointerEvents: "unset",
        cursor: "not-allowed",
        opacity: "0.5",
      },
    },
  },
}));
