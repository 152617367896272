import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "data-layer/store";
import { fetchDevice, putDevice, loadDeviceMeasuremetns } from "utils/https";

interface LoadDevice {
  iccid: string;
}

export const loadDevice = createAsyncThunk<
  { iccid: string; onlineAt: string },
  LoadDevice,
  { state: RootState }
>("device/loadDevice", async (params, { rejectWithValue }) => {
  try {
    const { iccid } = params;
    const deviceData = await fetchDevice(iccid);
    return deviceData;
  } catch (error) {
    return rejectWithValue("Failed to load device data");
  }
});

interface UpdateDevice {
  iccid: string;
  data: any;
}
export const updateDevice = createAsyncThunk<
  { iccid: string; data: any },
  UpdateDevice,
  { state: RootState }
>("device/updatedDevice", async (params) => {
  const { iccid, data } = params;
  const updatedDeviceData = await putDevice(iccid, data);

  return updatedDeviceData;
});

interface LoadDeviceMeasurements {
  iccid: string;
}

export const loadDeviceMeasurements = createAsyncThunk<
  { iccid: string },
  LoadDeviceMeasurements,
  { state: RootState }
>(
  "device/loadDeviceMeasurements",
  async (params) => await loadDeviceMeasuremetns(params.iccid),
);

export const saveDeviceIccid = createAsyncThunk<
  { iccid: string },
  string,
  { state: RootState }
>("device/saveDeviceIccid", async (iccid) => ({ iccid }));
