import React from "react";
import "./App.scss";
import { Routes } from "./Routes";
import Backdrop from "@mui/material/Backdrop/Backdrop";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";

function App() {
  return (
    <React.Suspense
      fallback={
        <Backdrop
          open={true}
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      }
    >
      <Routes />
    </React.Suspense>
  );
}

export default App;
