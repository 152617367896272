import { ROUTES } from "const/app-routes";
import removeFirstPouch from "../../assets/Videos/Manuals/Load new strip/Riv av tomma.mp4";
import openLid from "../../assets/Videos/Manuals/Load new strip/Oppna lucka.mp4";
import startLoading from "../../assets/Videos/Manuals/Load new strip/Placera dosrulle i fack.mp4";
import loadStripVideo from "../../assets/Videos/Manuals/Load new strip/Riv av tomma.mp4";
import unLoadStripVideo from "../../assets/Videos/Manuals/Unload Strip/Mata_ut_dosrulle.mp4";
import joinStripsVideo from "../../assets/Videos/Manuals/Splice strip/Skarva_dosrulle_kort.mp4";

type Step = {
  type: string;
  next: number | null;
  prev: number | null;
  [key: string]: any;
};
type Config = { id: string; steps: Step[] };

enum ConfigKeys {
  DosellSoundLevel = "dosellSoundLevel",
  DosellDaysBeforeLastDispDateNotif = "dosellDaysBeforeLastDispDateNotif",
  DosellMinBeforeUncolSachetNotif = "dosellMinBeforeUncolSachetNotif",
}

type ConfigProps = {
  localization: any;
  navigate: (route: string) => void;
  onSubmit: () => void;
  handleNext: () => void;
  unloadSubmit: (onSuccess: () => void) => void;
};

export const getConfig = ({
  localization,
  navigate,
  onSubmit,
  handleNext,
  unloadSubmit,
}: ConfigProps): Config[] => [
  {
    id: "load-strip",
    steps: [
      {
        type: "video",
        videoUrl: removeFirstPouch,
        infoText: localization.loadStrip.removeEmptyStrips,
        bodyText: localization.loadStrip.removeEmptyStripDone,
        next: 1,
        prev: null,
      },
      {
        type: "date-time",
        topText: localization.loadStrip.enterFirstDoseDate,
        dateFieldId: "firstDispDate",
        timeFieldId: "firstDispTime",
        next: 2,
        prev: 0,
      },
      {
        type: "date-time",
        topText: localization.loadStrip.enterLastDoseDate,
        dateFieldId: "lastDispDate",
        next: 3,
        prev: 1,
      },
      {
        type: "video",
        videoUrl: openLid,
        infoText: localization.loadStrip.openLid,
        bodyText: localization.loadStrip.openLidSubmit,
        next: 4,
        prev: 2,
      },
      {
        type: "video",
        videoUrl: startLoading,
        infoText: localization.loadStrip.putDoseInCompartment,
        bodyText: localization.loadStrip.putDoseInCompartmentLoad,
        submitButtonLabel: localization.loadStrip.load,
        modalText: localization.loadStrip.modalText,
        modalTitle: localization.loadStrip.modalTitle,
        onSubmit: onSubmit,
        next: null,
        prev: 3,
      },
    ],
  },
  {
    id: "unload-strip",
    steps: [
      {
        type: "video",
        videoUrl: unLoadStripVideo,
        infoText: localization.unloadStrip.openLid,
        bodyText: localization.unloadStrip.openLidSubmit,
        next: 1,
        prev: null,
      },
      {
        type: "video",
        videoUrl: unLoadStripVideo,
        infoText: localization.unloadStrip.putStrip,
        bodyText: localization.unloadStrip.putStripLoad,
        submitButtonLabel: localization.unloadStrip.load,
        onSubmit: () => unloadSubmit(() => navigate(ROUTES.MENU)),
        next: null,
        prev: 0,
      },
    ],
  },
  {
    id: "configuration",
    steps: [
      {
        type: "configuration",
        inputs: [
          {
            key: ConfigKeys.DosellSoundLevel,
            label: localization.configuration.volume,
            options: [
              { value: 0, label: localization.configuration.default },
              { value: 1, label: localization.configuration.low },
              { value: 2, label: localization.configuration.medium },
              { value: 3, label: localization.configuration.high },
            ],
          },
          {
            key: ConfigKeys.DosellDaysBeforeLastDispDateNotif,
            label: localization.configuration.lastDispNotification,
            options: [
              { value: 1, label: 1 },
              { value: 2, label: 2 },
              { value: 3, label: 3 },
              { value: 4, label: 4 },
              { value: 5, label: 5 },
            ],
          },
          {
            key: ConfigKeys.DosellMinBeforeUncolSachetNotif,
            label: localization.configuration.notificationDelay,
            options: [
              { value: 10, label: 10 },
              { value: 15, label: 15 },
              { value: 30, label: 30 },
              { value: 60, label: 60 },
              { value: 90, label: 90 },
              { value: 120, label: 120 },
              { value: 180, label: 180 },
              { value: 240, label: 240 },
              { value: 255, label: 255 },
            ],
          },
        ],
        next: null,
        prev: null,
        submitButtonLabel: localization.general.submit,
        onSubmit: () => navigate(ROUTES.MENU),
      },
    ],
  },
  {
    id: "join-strips",
    steps: [
      {
        type: "video",
        videoUrl: unLoadStripVideo,
        infoText: localization.joinStrips.openLid,
        bodyText: localization.joinStrips.openLidSubmit,
        next: 1,
        prev: null,
      },
      {
        type: "video",
        videoUrl: unLoadStripVideo,
        infoText: localization.joinStrips.putDoseInCompartment,
        bodyText: localization.joinStrips.putDoseInCompartmentLoad,
        onSubmit: () => unloadSubmit(handleNext),
        submitButtonLabel: localization.joinStrips.load,
        next: 2,
        prev: 0,
      },
      {
        type: "video",
        videoUrl: joinStripsVideo,
        next: 3,
        prev: 1,
      },
      {
        type: "date-time",
        topText: localization.loadStrip.enterFirstDoseDate,
        dateFieldId: "firstDispDate",
        timeFieldId: "firstDispTime",
        next: 4,
        prev: 2,
      },
      {
        type: "date-time",
        topText: localization.joinStrips.enterLastDoseDate,
        dateFieldId: "lastDispDate",
        next: 5,
        prev: 3,
      },
      {
        type: "video",
        videoUrl: loadStripVideo,
        infoText: localization.joinStrips.openLid,
        bodyText: localization.joinStrips.openLidSubmit,
        next: 6,
        prev: 4,
      },
      {
        type: "video",
        videoUrl: loadStripVideo,
        infoText: localization.joinStrips.putDoseInCompartment,
        bodyText: localization.joinStrips.putDoseInCompartmentLoad,
        submitButtonLabel: localization.joinStrips.load,
        modalText: localization.joinStrips.modalText,
        modalTitle: localization.joinStrips.modalTitle,
        onSubmit: onSubmit,
        next: null,
        prev: 5,
      },
    ],
  },
];
