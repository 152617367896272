import axiosInstance from "./config";

// API function using the configured Axios instance
export const fetchDevice = async (iccid: string) => {
  try {
    const response = await axiosInstance.get(`/device/${iccid}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const putDevice = async (iccid: string, data: any) => {
  try {
    const response = await axiosInstance.patch(`/device/${iccid}`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const loadDeviceMeasuremetns = async (iccid: string) => {
  try {
    const response = await axiosInstance.get(`/device/measurements/${iccid}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
