import Box from "@mui/material/Box/Box";
import Text from "components/Text/Text";
import Button from "components/Button/Button";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import { DatePicker } from "components/DatePicker/DatePicker";
import { TimePicker } from "components/TimePicker/TimePicker";
import localization from "localization/sv";
import { saveStepData } from "data-layer/currentStep/reducer";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "data-layer/store";
import { Stack } from "@mui/material";

type Props = {
  topText: string;
  dateFieldId: string;
  timeFieldId?: string;
  onNext: () => void;
  onPrevious: () => void;
};

const DateTimeSelector = ({
  dateFieldId,
  timeFieldId,
  onNext,
  onPrevious,
  topText,
}: Props) => {
  const dispatch = useDispatch<AppDispatch>();

  const onValueChanged = (fieldId: string) => (date: number | null) =>
    date &&
    dispatch(
      saveStepData({
        fieldId: fieldId,
        date: date,
      }),
    );

  const currentState = useSelector((state: RootState) => state.currentState);

  return (
    <Box sx={{ flexGrow: 1, padding: 4 }}>
      <Text type="body1">{topText}</Text>

      <Box sx={{ margin: "15px 0" }}>
        <DatePicker
          identifier={dateFieldId}
          value={currentState[dateFieldId]}
          onChange={onValueChanged(dateFieldId)}
        />
      </Box>
      {timeFieldId && (
        <Box sx={{ margin: "0 0 15px 0" }}>
          <TimePicker
            identifier={timeFieldId}
            value={currentState[timeFieldId]}
            onChange={onValueChanged(timeFieldId)}
          />
        </Box>
      )}

      <Stack direction="row" spacing={{ xs: 2, sm: 2 }}>
        <Button
          startIcon={<HighlightOffOutlinedIcon />}
          title={localization.general.cancel}
          color="error"
          onClick={onPrevious}
        ></Button>
        <Button
          startIcon={<CheckCircleOutlineIcon />}
          title={localization.general.submit}
          color="primary"
          onClick={onNext}
        ></Button>
      </Stack>
    </Box>
  );
};

export default DateTimeSelector;
