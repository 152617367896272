const localization = {
  general: {
    submit: "Bekräfta",
    cancel: "Avbryt",
    selectAllFields: "Alla fält behöver vara ifyllda för att fortsätta",
  },
  qr: {
    scan: "Scanna QR-koden på Dosell för att ansluta till den",
  },
  menu: {
    loadStrip: "Ladda dosrulle",
    joinStrip: "Skarva dosrulle",
    unloadStrip: "Mata ut dosrulle",
    configuartion: "Konfiguration",
    manual: "Manualer",
    stripUnloaded: "Ingen dosrulle laddad",
    stripIsUnloading: "Laddar ur dosrulle",
    stripIsLoading: "Laddar dosrulle",
    stripLoaded: "Dosrulle laddad",
    nextDispensationAt: "Nästa dispenseringstid",
    deviceNotFound: "Dosell hittades inte",
  },
  joinStrips: {
    openLid: "Öppna luckan",
    openLidSubmit: "Var god öppna luckan. Klicka sedan på Bekräfta",
    putDoseInCompartment: "Placera dosrulle i fack och häll remsan mot hjulen",
    putDoseInCompartmentLoad:
      "Placera dosrullen i facket och klicka sedan på Ladda",
    load: "Ladda",
    enterFirstDoseDate: "Ange tid och datum för första doseringen på dosrullen",
    enterLastDoseDate: "Ange datum för sista dospåsen i dosrullen",
    modalText:
      "Lampa 3 på sidan av Dosell kommer att lysa grönt när laddningen är klar",
    modalTitle: "Stäng luckan",
  },
  loadStrip: {
    removeEmptyStrips: "Ta bort eventuell första tomma dospåse från dosrullen",
    enterFirstDoseDate: "Ange tid och datum för första dospåsen på dosrullen",
    removeEmptyStripDone:
      "Klicka på Bekräfta när den första dospåsen har tagits bort",
    enterLastDoseDate: "Ange datum för sista dospåsen i dosrullen",
    openLid: "Öppna luckan",
    openLidSubmit: "Var god öppna luckan. Klicka sedan på Bekräfta",
    putDoseInCompartment:
      "Placera dosrullen i facket, håll dospåsen mot hjulen och klicka sedan på Ladda",
    putDoseInCompartmentLoad:
      "Placera dosrullen i facket och klicka sedan på Ladda",
    load: "Ladda",
    modalText:
      "Lampa 3 på sidan av Dosell kommer att lysa grönt när laddningen är klar",
    modalTitle: "Stäng luckan",
  },
  unloadStrip: {
    openLid: "Öppna luckan",
    openLidSubmit: "Var god öppna luckan. Klicka sedan på Bekräfta",
    putStrip: "Tryck på bekräfta för att starta utmatningen",
    putStripLoad: "När dosrullen har matats ut, stäng då locket.",
  },
  configuration: {
    volume: "Volym",
    localNotification: "Lokal påminnelse före sista dispenseringsdag (dagar)",
    lastDispNotification:
      "Skicka påminnelse till mottagare om sista dispenseringsdag (antal dagar före sista dispenseringsdag)",
    notificationDelay:
      "Antal minuter som Dosell väntar med att skicka meddelande till behörig mottagare om att Dospåsen inte är tagen",
    default: "Standard",
    low: "Låg",
    medium: "Medium",
    high: "Hög",
  },
};

export default localization;
