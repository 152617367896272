export const mergeDateAndTime = (
  dateStr: string,
  timeStr: string,
): Date | null => {
  if (!dateStr || !timeStr) return null;

  const date = new Date(dateStr);
  const time = new Date(timeStr);

  const combinedDateTime = new Date(
    date.getFullYear(), // Year from date
    date.getMonth(), // Month from date
    date.getDate(), // Day from date
    time.getHours(), // Hours from time
    time.getMinutes(), // Minutes from time
    time.getSeconds(), // Seconds from time
  );

  return combinedDateTime;
};
