import { combineReducers } from "@reduxjs/toolkit";
import device from "./device/reducer";
import currentState from "./currentStep/reducer";

const rootReducer = combineReducers({
  device,
  currentState,
});

export default rootReducer;
