import { useState } from "react";
import { getConfig } from "./config";
import { useNavigate, useParams } from "react-router-dom";
import VideoInstruction from "views/FlowManager/VideoInstruction/VideoInstruction";
import DateTimeSelector from "views/FlowManager/DateTimeSelector/DateTimeSelector";
import Configuration from "views/FlowManager/Configuration/Configuration";
import useLocalization from "localization";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "data-layer/store";
import { mergeDateAndTime } from "utils/dateTime";
import { updateDevice } from "data-layer/device/actions";
import { ROUTES } from "const/app-routes";
import { clearStepData } from "data-layer/currentStep/reducer";

export enum DosellStripControl {
  LOAD = "load",
  UNLOAD = "unload",
}
const FlowManager = () => {
  const navigate = useNavigate();
  const { flowId } = useParams();
  const [currentStep, setCurrentStep] = useState(0);
  const localization = useLocalization();
  const dispatch = useDispatch<AppDispatch>();

  const { firstDispDate, firstDispTime, lastDispDate } = useSelector(
    (state: RootState) => state.currentState,
  );
  const { iccid } = useSelector((state: RootState) => state.device);

  const onSubmit = async () => {
    if (firstDispDate && firstDispTime && lastDispDate) {
      const mergedDateTime = mergeDateAndTime(
        new Date(firstDispDate).toISOString(),
        new Date(firstDispTime).toISOString(),
      );

      const data = {
        dosellStripCtrl: DosellStripControl.LOAD,
        dosellFirstDispTime: mergedDateTime?.toISOString(),
        dosellLastDispDate: new Date(lastDispDate).toISOString(),
      };

      await dispatch(updateDevice({ iccid, data }));
      await dispatch(clearStepData());
      return false;
    } else {
      alert(localization.general.selectAllFields);
      return true;
    }
  };

  const unloadSubmit = async (onSuccess?: () => void) => {
    const data = {
      dosellStripCtrl: DosellStripControl.UNLOAD,
    };
    await dispatch(updateDevice({ iccid, data }));
    onSuccess && onSuccess();
  };

  const onConfigurationSubmit = async (data: any) => {
    await dispatch(updateDevice({ iccid, data }));
    navigate(ROUTES.MENU);
  };

  const handleNext = () =>
    step?.next ? setCurrentStep(step.next) : navigate(ROUTES.MENU);
  const handlePrevious = () =>
    step?.prev ? setCurrentStep(step.prev) : navigate(ROUTES.MENU);

  const flow = getConfig({
    localization,
    navigate,
    onSubmit,
    unloadSubmit,
    handleNext,
  }).find((f) => f.id === flowId);
  const step = flow?.steps[currentStep];

  const renderStep = () => {
    switch (step?.type) {
      case "video":
        return (
          <VideoInstruction
            videoUrl={step.videoUrl}
            infoText={step.infoText}
            onNext={handleNext}
            bodyText={step.bodyText}
            onPrevious={handlePrevious}
            submitButtonLabel={step.submitButtonLabel}
            onSubmit={step.onSubmit}
            modalText={step.modalText}
            modalTitle={step.modalTitle}
          />
        );
      case "date-time":
        return (
          <DateTimeSelector
            dateFieldId={step.dateFieldId}
            timeFieldId={step.timeFieldId}
            topText={step.topText}
            onNext={handleNext}
            onPrevious={handlePrevious}
          ></DateTimeSelector>
        );
      case "configuration":
        return (
          <Configuration
            inputs={step.inputs}
            onSubmit={onConfigurationSubmit}
            submitButtonLabel={step.submitButtonLabel}
          ></Configuration>
        );
      default:
        return null;
    }
  };

  return <>{renderStep()}</>;
};

export default FlowManager;
