export const typography = {
  htmlFontSize: 16,
  fontSize: 14,
  h1: {
    fontSize: "1.75rem", //28px
    fontWeight: "700",
    lineHeight: "2.625rem",
  },
  h2: {
    fontSize: "1.5rem", //24px
    fontWeight: "700",
  },
  h3: {
    fontSize: "1.375rem", //22px
    fontWeight: "700",
  },
  h4: {
    fontSize: "1.25rem", //20px
    fontWeight: "700",
  },
  h5: {
    fontSize: "1rem", //16px
    fontWeight: "700",
    lineHeight: "1.74rem",
  },
  h6: {
    fontSize: "0.875rem", //14px
    fontWeight: "700",
    lineHeight: "1.2rem",
  },
  subtitle1: {
    fontSize: "0.875rem", //14px
    fontWeight: "600",
    lineHeight: "1.2rem",
  },
  subtitle2: {
    fontSize: "0.875rem", //14px
    fontWeight: "500",
    lineHeight: "1.2rem",
  },
  body1: {
    fontSize: "1rem", //16px
    fontWeight: "500",
    lineHeight: "1.2rem",
  },
  body2: {
    fontSize: "0.875rem", //14px
    fontWeight: "500",
    lineHeight: "1.313rem",
  },
  body3: {
    fontSize: "0.75rem", //12px
    fontWeight: "500",
    lineHeight: "1.2rem",
  },
  caption: {
    fontSize: "0.75rem", //12px
    fontWeight: "500",
  },
  overline: {
    fontSize: "0.75rem", //12px + uppercase by default
  },
};
