import {
  Select,
  outlinedInputClasses,
  selectClasses,
  styled,
  svgIconClasses,
} from "@mui/material";
import { theme } from "config";

export const StyledSelect = styled(Select)(() => ({
  [`& .${selectClasses.select}`]: {
    border: 0,
    color: theme.palette.primary.light,
  },
  [`&.${outlinedInputClasses.root}`]: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.body2.fontWeight,
    borderRadius: "20px",

    [`& .${svgIconClasses.root}`]: {
      color: theme.palette.grey[300],
    },

    [`&.${outlinedInputClasses.multiline}`]: {
      padding: "14px 20px",
    },

    // Hover
    [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
      borderColor: theme.palette.primary.main,
      borderWidth: "1px",
    },

    // Focused
    [`&.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]:
      {
        borderWidth: "1px",
      },

    [`& .${outlinedInputClasses.input}`]: {
      color: theme.palette.secondary,
      padding: "14px 20px",

      "&::placeholder": {
        color: "red",
        fontSize: theme.typography.body2.fontSize,
        fontWeight: theme.typography.body2.fontWeight,
        opacity: 1,
      },

      [`&.${outlinedInputClasses.inputMultiline}`]: {
        color: theme.palette.secondary,
        padding: 0,
      },

      [`&.${outlinedInputClasses.inputAdornedStart}`]: {
        paddingLeft: 0,
      },
    },

    [`& .${outlinedInputClasses.notchedOutline}`]: {
      borderColor: theme.palette.grey[200],
    },

    [`&.${outlinedInputClasses.sizeSmall}`]: {
      height: "30px",
      width: "40px",
      padding: 0,

      [`& .${outlinedInputClasses.inputSizeSmall}`]: {
        padding: "0 4px",
        textAlign: "center",
      },
    },
  },
}));

const styles = {
  errorMesage: {
    color: theme.palette.error.main,
  },
};

export default styles;
